.inputField {
  flex: 1 1;
  font-size: 16px;
  overflow: hidden;
  background-color: #ffcc01!important;
  border: 1px solid #fff;
  font-family: Arial;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: #000!important;
  font-weight: 700;
}

.inputField::placeholder {
  font-size: 12px;
  color: #fff !important;
}

.inputMessage{
  height: 40px;
  flex: 1;
  border-radius:5px;
}