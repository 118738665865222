@import './colors';

@mixin theme ($name, $default_white, $default_black, $default_yellow, $default_gray, $default_blue_bahama, $default_biscay, $default_malibu) {
    .#{$name}{
        &[class*='button']{
            background-color: $default_yellow;
            color: $default_black;
        }
        &[class*='button'][class*='empty']{
            background-color: transparent;
            color: $default_malibu;
            border-color: $default_malibu;
            font-weight: bold;
        }
        &[class*='button-secondary']{
            background-color: $default_biscay;
            color: $default_white;
        }
        &[class*='button-active']{
            background-color: $default_yellow;
            color: $default_white;
        }
        &[class*='button-inactive']{
            background-color: $default_white;
            color: $default_black;
            border: 1px solid $default_black;
        }
        &[class*='inputText']{
            background-color: $default_white;
            color: $default_black;
            // border: 1px solid rgba($default_black, .5);
            box-shadow: 0 0 3px rgba($default_black, .3);
            &::placeholder{
                color: rgba($default_black, .4);
            }
        }
        &[class*='inputMessage']{
            box-shadow: 0 0 3px rgba($default_black, .2);
        }
        &[class*='wrapper-sign']{
            background-color: $default_gray;
						background-image: url('../Assets/Img/turntable.jpg');
						background-size: cover;
        }
        &[class*='wrapper-type-switch']{
            box-shadow: 0 0 10px rgba($default_black, .1);
        }
        &[class*='wrapper-creation-options']{

        }
        &[class*='wrapper-main-app']{
            background-color: $default_white;
        }
        &[class*='FPContainer']{
            >[class*='FPBox']{
                color: $default_gray;
                background-color: $default_white;
                >[class*='FPSelect']{
                    box-shadow: 0 0 3px rgba($default_black, .3);
                }
            }
        }
        &[class*='titleBar']{
            background-color: #e41f28;
            color: $default_gray;
            box-shadow: 0 0 10px rgba($default_black, .2);
        }
        &[class*='hamburger__item']{
            background-color: $default_gray;
        }
        &[class*='taskCard']{
            background-color: $default_white;
            box-shadow: 0 0 20px rgba($default_blue_bahama, .1);
            >[class*='taskCard__left']{
                >[class*='taskCard__date']{
                    color: $default_blue_bahama;
                }
                >[class*='taskCard__month']{
                    color: $default_biscay;
                }
            }
            >[class*='taskCard__right']{
                border-color: rgba($default_black, .2);
                >[class*='taskCard__title']{
                    color: $default_biscay;
                }
                >[class*='taskCard__status']{
                    color: $default_biscay;
                }
            }
            >[class*='taskCard__arrow']{
                border-color: rgba($default_black, .4);
            }
        }
        &[class*='taskListColumn']{
            border-color: rgba($default_black, .5);
        }
        &[class*='messageUser']{
            color: $default_white;
            background-color: $default_biscay;
            &::after{
                background-color: $default_biscay;
            }
        }
        &[class*='messageApp']{
            color: $default_white;
            background-color: $default_gray;
            &::after{
                background-color: $default_gray;
            }
        }
        &[class*='messageInputContainer']{
            background-color: $default_white;
            box-shadow: 0 0 10px rgba($default_black, .1);
        }
        &[class*='taskDetailsHead']{
            box-shadow: 0 0 10px rgba($default_black, .1);
            // background-color: rgba($default_gray, .1);
            // @media (min-width:992px) {
                //     background-color: transparent;
                // }
            >[class*='taskDetailsHeadText']{
                color: $default_gray;
                border-color: rgba($default_gray, .3);
            }
        }
        &[class*='userProfileContainer']{
            color: $default_white;
            background-color: $default_gray;
            background-image: url('../Assets/Img/menu-bg.jpg');
            background-size: cover;
        }
        &[class*='chatArea']{
            background-color: $default_white;
        }
        &[class*='NewTaskContainer']{
            background-color: rgba($default_black, .8);
            >[class*='NewTaskBlock']{
                background-color: $default_white;
                color: $default_gray;
                >[class*='NTClose']{
                    color: $default_gray;
                }
            }
        }
        &[class*='NewTaskMediaAttachmentList']{
            border-color: transparent;
        }
        &[class*='MediaAttachmentList']{
            background-color: rgba($default_blue_bahama, .7);
        }
        &[class*='MediaAttachmentListItem']{
            background-color: $default_gray;
            border-color: $default_white;
        }
        &[class*='AlertMessageContainer']{
            border-color: $default_yellow;
        }
        &[class*='AlertMessagePage']{
            color: $default_white;
        }
        &[class*='AlertLoader']{
            border-color: $default_white;
            border-top-color: $default_yellow;
        }



        &[class*='icon']{
            color: inherit;
        }
    }
}

@include theme('default', $default_white, $default_black, $default_yellow, $default_gray, $default_blue_bahama, $default_biscay, $default_malibu);
