.MMenu_P {
  font-size: 20px;
}

.MModal_Button_Hey {
	color: #000 !important;
	font-weight: bold;
	font-size: 11px;
	background-color: #FFCC01;
	background-size: 100%;
	background-repeat: no-repeat;
	font-weight: 400;
	padding: 15px 15px;
	border: 2px solid #000;
	margin: 20px;
	line-height: 60px;
  	cursor: help;
}

.neonGo {animation: flicker 3.2s infinite alternate;}
.neonPop {animation: flicker2 4s infinite alternate;}
.neonChill {animation: flicker3 3.5s infinite alternate;}

:root {
/* Base font size */
/* Set neon color */
--neon-text-color: #f40;
--neon-border-color: #ea00ff;
--neon-border-color2: #ffeb49;
--neon-border-color3: #00c59e;
}
/* Animate neon flicker */
@keyframes flicker {

0%, 35%, 55%, 80%, 95% {
      -webkit-filter: drop-shadow(0px 0px 15px var(--neon-border-color));
    }
10%, 45%, 65%, 90%, 100% {
-webkit-filter: drop-shadow(0px 0px 9px var(--neon-border-color));
}

    20%, 24%, 55% {
        text-shadow: none;
        box-shadow: none;
    }
}
@keyframes flicker2 {

10%, 45%, 65%, 90%, 100% {
      -webkit-filter: drop-shadow(0px 0px 15px var(--neon-border-color2));
filter: drop-shadow(0px 0px 15px var(--neon-border-color2));
    }
0%, 15%, 55%, 80%, 95% {
-webkit-filter: drop-shadow(0px 0px 9px var(--neon-border-color2));
filter: drop-shadow(0px 0px 9px var(--neon-border-color2));
}

    20%, 24%, 55% {
        text-shadow: none;
        box-shadow: none;
    }
}
@keyframes flicker3 {

0%, 15%, 55%, 80%, 95% {
      -webkit-filter: drop-shadow(0px 0px 15px var(--neon-border-color3));
filter: drop-shadow(0px 0px 15px var(--neon-border-color3));
    }
10%, 45%, 65%, 90%, 100% {
-webkit-filter: drop-shadow(0px 0px 9px var(--neon-border-color3));
filter: drop-shadow(0px 0px 9px var(--neon-border-color3));
}

    20%, 24%, 55% {
        text-shadow: none;
        box-shadow: none;
    }
}
