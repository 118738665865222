.modalBackground {
  position: absolute;
  top: 0;
  left: 0;
  background: #e41f28;
  padding: 20px;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-image: url('../../Assets/Img/turntable.png');
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	opacity: 1;
  overflow: none;

  &:focus {
    outline: 0;
  }
}

.modalContainer	{
  overflow-y: auto;
  padding: 40px 20px 20px;
  background: #e41f28;

  .closeModal {
    position: fixed;
    top: 40px;
    right: 50px;
    background: none;
    border: 1px solid #fff;
    color: #fdc009;
    cursor: pointer;
  }

  .modalContent {
    overflow: scroll;

    h3 {
      text-align: center;
      color: #fdc009;
      font-size: 32px;
      margin-bottom: 10px;
    }

    .IframeContainer {
      width: 100%;
      height: 620px;
      overflow: scroll;

  
      iframe {
        overflow: scroll;
        width: 100% !important;
        height: 100% !important;
        margin: auto;
        display: block;
      }
    }
  }
}
