@charset "UTF-8";
html {
  box-sizing: border-box;
  font-family: sans-serif; }

body {
  line-height: 1;
  font-weight: normal;
  margin: 0;
  padding: 0;
  background-color: #666666;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0; }

input, a, button, select, div, textarea {
  text-decoration: none !important;
  outline: none !important;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }
  input:active, a:active, button:active, select:active, div:active, textarea:active {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }
  input:hover, a:hover, button:hover, select:hover, div:hover, textarea:hover {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

*,
*::before,
*::after {
  box-sizing: inherit; }

.AppContainer {
  overflow: hidden;
  position: absolute;
  right: 0px;
  /*will be 0*/
  left: 0px;
  top: 0px;
  bottom: 0px; }

.PageContainer {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  flex-direction: column; }

.ContentContainer {
  position: relative;
  top: 30%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.CenterTitle {
  text-align: center;
  padding: 10px; }

.MainAppContainer {
  height: 100%; }

@font-face {
  font-family: 'fontello';
  src: local("fontello"), local("fontello"), url(../../static/media/fontello.ccef59c1.eot), url(../../static/media/fontello.ccef59c1.eot#iefix) format("embedded-opentype"), url(../../static/media/fontello.89af3988.woff2) format("woff2"), url(../../static/media/fontello.f37472fe.woff) format("woff"), url(../../static/media/fontello.8358ce60.ttf) format("truetype"), url(../../static/media/fontello.5323b406.svg#fontello) format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-left:before {
  content: '\E800'; }

/* '' */
.icon-logout:before {
  content: '\E805'; }

/* '' */
.icon-paper-plane:before {
  content: '\F1D8'; }

/* '' */
.icon-doc-add:before {
  content: '\E801'; }

.icon-pencil:before {
  content: '\E802'; }

/* '' */
.icon-credit-card:before {
  content: '\E803'; }

.icon-cancel:before {
  content: '\E804'; }

.color-white {
  color: #FFFFFF !important; }

.color-black {
  color: #000 !important; }

.color-default-gray {
  color: #1D1D1D !important; }

.fz-10 {
  font-size: 10px !important;
  line-height: 12px !important; }
  @media (min-width: 375px) {
    .fz-10 {
      font-size: 10px !important;
      line-height: 14px !important; } }

.fz-11 {
  font-size: 11px !important;
  line-height: 13px !important; }
  @media (min-width: 375px) {
    .fz-11 {
      font-size: 11px !important;
      line-height: 15px !important; } }

.fz-12 {
  font-size: 12px !important;
  line-height: 14px !important; }
  @media (min-width: 375px) {
    .fz-12 {
      font-size: 12px !important;
      line-height: 16px !important; } }

.fz-13 {
  font-size: 13px !important;
  line-height: 15px !important; }
  @media (min-width: 375px) {
    .fz-13 {
      font-size: 13px !important;
      line-height: 17px !important; } }

.fz-14 {
  font-size: 13px !important;
  line-height: 16px !important; }
  @media (min-width: 375px) {
    .fz-14 {
      font-size: 14px !important;
      line-height: 18px !important; } }

.fz-15 {
  font-size: 14px !important;
  line-height: 17px !important; }
  @media (min-width: 375px) {
    .fz-15 {
      font-size: 15px !important;
      line-height: 19px !important; } }

.fz-16 {
  font-size: 15px !important;
  line-height: 18px !important; }
  @media (min-width: 375px) {
    .fz-16 {
      font-size: 16px !important;
      line-height: 20px !important; } }

.fz-18 {
  font-size: 17px !important;
  line-height: 20px !important; }
  @media (min-width: 375px) {
    .fz-18 {
      font-size: 18px !important;
      line-height: 22px !important; } }

.fz-20 {
  font-size: 19px !important;
  line-height: 22px !important; }
  @media (min-width: 375px) {
    .fz-20 {
      font-size: 20px !important;
      line-height: 24px !important; } }

.fz-25 {
  font-size: 24px !important;
  line-height: 27px !important; }
  @media (min-width: 375px) {
    .fz-25 {
      font-size: 25px !important;
      line-height: 29px !important; } }

.fz-30 {
  font-size: 29px !important;
  line-height: 32px !important; }
  @media (min-width: 375px) {
    .fz-30 {
      font-size: 30px !important;
      line-height: 34px !important; } }

.min-w-300 {
  min-width: 300px; }

.max-w-100 {
  max-width: 100px; }

.min-h-100vh {
  min-height: 100vh; }

.h-40 {
  height: 40px !important; }

.uppercase {
  text-transform: uppercase !important; }

.break-word {
  word-break: break-all; }

.bold {
  font-weight: bold !important; }

.text-left {
  text-align: left !important; }

.ai-end {
  align-items: flex-end; }

.flex-1 {
  flex: 1 1; }

.w-100 {
  width: 100% !important; }

.d-b {
  display: block !important; }

.no-radius {
  border-radius: 0 !important; }

.zi-2 {
  z-index: 2; }

@media (min-width: 992px) {
  .hide-lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hide-xl {
    display: none !important; } }

@media (max-width: 991px) {
  .show-lg {
    display: none !important; } }

@media (max-width: 1199px) {
  .show-xl {
    display: none !important; } }

.shrink-0 {
  flex-shrink: 0; }

.wrapper {
  display: block;
  width: auto;
  position: relative; }
  .wrapper_100 {
    width: 100%; }
  .wrapper_flex-center {
    display: flex;
    justify-content: center;
    align-items: center; }
  .wrapper_flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .wrapper_flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .wrapper_flex-column-end {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; }
  .wrapper_page {
    min-width: 100vw;
    min-height: 100vh;
    max-width: 100vw;
    max-height: 100vh; }
  .wrapper_left-half {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .wrapper_right-half {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .wrapper_tasks-list {
    position: relative;
    flex: 1 1;
    padding: 30px 15px;
    overflow-x: hidden;
    overflow-y: auto; }
    @media (min-width: 576px) {
      .wrapper_tasks-list {
        padding: 30px; } }
  .wrapper_cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease; }
    .wrapper_cover-hide {
      opacity: 0; }
    .wrapper_cover-fixed {
      position: fixed; }
  .wrapper-main-app {
    width: 100vw;
    height: 100vh;
    overflow: hidden; }
    @media (max-width: 992px) {
      .wrapper-main-app {
        height: 90vh; } }
  .wrapper-sign {
    overflow-y: auto; }
  .wrapper-creation-options {
    padding: 15px 0; }
    @media (min-width: 992px) {
      .wrapper-creation-options {
        padding: 30px 0; } }
  .wrapper-type-switch {
    padding: 15px 0; }
    @media (min-width: 992px) {
      .wrapper-type-switch {
        padding: 30px 0; } }

.ml-10 {
  margin-left: 10px; }

.ml-15 {
  margin-left: 15px; }

.ml-25 {
  margin-left: 25px; }

.mr-10 {
  margin-right: 10px; }

.mr-15 {
  margin-right: 15px; }

.mt-3 {
  margin-top: 3px; }

.mt-5 {
  margin-top: 5px; }

.mt-10 {
  margin-top: 10px; }

.mt-15 {
  margin-top: 15px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

.mt-50 {
  margin-top: 50px; }

.mt-80 {
  margin-top: 80px; }

.p-5 {
  padding: 5px !important; }

.p-10 {
  padding: 10px !important; }

.pr-5 {
  padding-right: 5px; }

.pt-30 {
  padding-top: 30px; }

.pt-50 {
  padding-top: 50px; }

.pb-30 {
  padding-bottom: 30px; }

.hamburger {
  width: 40px;
  height: 40px;
  position: relative; }
  .hamburger__item {
    display: block;
    width: 20px;
    height: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 10px; }
    .hamburger__item::before, .hamburger__item::after {
      content: '';
      display: block;
      width: 20px;
      height: 3px;
      position: absolute;
      left: 0;
      top: 0;
      background-color: inherit;
      border-radius: 10px; }
    .hamburger__item::before {
      -webkit-transform: translateY(-7px);
              transform: translateY(-7px); }
    .hamburger__item::after {
      -webkit-transform: translateY(7px);
              transform: translateY(7px); }

html{
  height:100vh;
  width:100vw;
  overflow: hidden;
}

body{
  background: #2e2e2e;
  height:100vh;
  width:100vw;
}

.MyPage{
  width:100vw;
  height:100vh;
  display:flex;
  flex-direction: column;
}

.BlueUIButton{
  height:40px;
  border-radius: 15px;
  background:#009fe3;
  border:none;
  font-size: 14px;
  font-weight: bold;
  color:white;
}

.BlueUIButton:active{
  outline:none;
  color:#009fe3;
  background:white;

}

.BlueUIButton:focus{
  outline:none;
}

.WhiteUIButton{
  height:40px;
  border-radius: 15px;
  background:white;
  border:none;
  font-size: 14px;
  font-weight: bold;
  color:#009fe3;
}

.WhiteUIButton:active{
  outline:none;
  background:#009fe3;
  color:white;
}

.WhiteUIButton:focus{
  outline:none;
}

.ErrorUIButton{
  height:40px;
  border-radius: 15px;
  background:darkred;
  border:none;
  font-size: 14px;
  font-weight: bold;
  color:white;
}

.ErrorUIButton:active{
  outline:none;
  background:darkred;
  color:darkgrey;
}

.ErrorUIButton:focus{
  outline:none;
}

.ClearUIButton{

}








.AlertLoaderPage {
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 10px;
  z-index: 1000; }

.AlertLoaderContainer {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-color: white;
  border: 1px solid #f3f3f3;
  /* Light grey */
  display: table;
  padding: 20px;
  border-radius: 10px;
  margin: 0px auto; }

.AlertLoader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  align-self: center;
  margin: 0px auto;
  margin-bottom: 20px; }

.AlertLoaderText {
  color: white;
  text-align: center; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.theme_color-white__1tavu {
  color: #FFFFFF !important; }

.theme_color-black__2Zvbw {
  color: #000 !important; }

.theme_color-default-gray__2KrrS {
  color: #1D1D1D !important; }

.theme_default__mSJ9-[class*='button'] {
  background-color: #FFCC01;
  color: #000; }

.theme_default__mSJ9-[class*='button'][class*='empty'] {
  background-color: transparent;
  color: #e41f28;
  border-color: #e41f28;
  font-weight: bold; }

.theme_default__mSJ9-[class*='button-secondary'] {
  background-color: #000;
  color: #FFFFFF; }

.theme_default__mSJ9-[class*='button-active'] {
  background-color: #FFCC01;
  color: #FFFFFF; }

.theme_default__mSJ9-[class*='button-inactive'] {
  background-color: #FFFFFF;
  color: #000;
  border: 1px solid #000; }

.theme_default__mSJ9-[class*='inputText'] {
  background-color: #FFFFFF;
  color: #000;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); }
  .theme_default__mSJ9-[class*='inputText']::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.4); }
  .theme_default__mSJ9-[class*='inputText']::-moz-placeholder {
    color: rgba(0, 0, 0, 0.4); }
  .theme_default__mSJ9-[class*='inputText']::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.4); }
  .theme_default__mSJ9-[class*='inputText']::placeholder {
    color: rgba(0, 0, 0, 0.4); }

.theme_default__mSJ9-[class*='inputMessage'] {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); }

.theme_default__mSJ9-[class*='wrapper-sign'] {
  background-color: #1D1D1D;
  background-image: url(../../static/media/turntable.c198b84e.jpg);
  background-size: cover; }

.theme_default__mSJ9-[class*='wrapper-type-switch'] {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

.theme_default__mSJ9-[class*='wrapper-main-app'] {
  background-color: #FFFFFF; }

.theme_default__mSJ9-[class*='FPContainer'] > [class*='FPBox'] {
  color: #1D1D1D;
  background-color: #FFFFFF; }
  .theme_default__mSJ9-[class*='FPContainer'] > [class*='FPBox'] > [class*='FPSelect'] {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); }

.theme_default__mSJ9-[class*='titleBar'] {
  background-color: #e41f28;
  color: #1D1D1D;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }

.theme_default__mSJ9-[class*='hamburger__item'] {
  background-color: #1D1D1D; }

.theme_default__mSJ9-[class*='taskCard'] {
  background-color: #FFFFFF;
  box-shadow: 0 0 20px rgba(228, 31, 40, 0.1); }
  .theme_default__mSJ9-[class*='taskCard'] > [class*='taskCard__left'] > [class*='taskCard__date'] {
    color: #e41f28; }
  .theme_default__mSJ9-[class*='taskCard'] > [class*='taskCard__left'] > [class*='taskCard__month'] {
    color: #000; }
  .theme_default__mSJ9-[class*='taskCard'] > [class*='taskCard__right'] {
    border-color: rgba(0, 0, 0, 0.2); }
    .theme_default__mSJ9-[class*='taskCard'] > [class*='taskCard__right'] > [class*='taskCard__title'] {
      color: #000; }
    .theme_default__mSJ9-[class*='taskCard'] > [class*='taskCard__right'] > [class*='taskCard__status'] {
      color: #000; }
  .theme_default__mSJ9-[class*='taskCard'] > [class*='taskCard__arrow'] {
    border-color: rgba(0, 0, 0, 0.4); }

.theme_default__mSJ9-[class*='taskListColumn'] {
  border-color: rgba(0, 0, 0, 0.5); }

.theme_default__mSJ9-[class*='messageUser'] {
  color: #FFFFFF;
  background-color: #000; }
  .theme_default__mSJ9-[class*='messageUser']::after {
    background-color: #000; }

.theme_default__mSJ9-[class*='messageApp'] {
  color: #FFFFFF;
  background-color: #1D1D1D; }
  .theme_default__mSJ9-[class*='messageApp']::after {
    background-color: #1D1D1D; }

.theme_default__mSJ9-[class*='messageInputContainer'] {
  background-color: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

.theme_default__mSJ9-[class*='taskDetailsHead'] {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
  .theme_default__mSJ9-[class*='taskDetailsHead'] > [class*='taskDetailsHeadText'] {
    color: #1D1D1D;
    border-color: rgba(29, 29, 29, 0.3); }

.theme_default__mSJ9-[class*='userProfileContainer'] {
  color: #FFFFFF;
  background-color: #1D1D1D;
  background-image: url(../../static/media/menu-bg.8e9c7700.jpg);
  background-size: cover; }

.theme_default__mSJ9-[class*='chatArea'] {
  background-color: #FFFFFF; }

.theme_default__mSJ9-[class*='NewTaskContainer'] {
  background-color: rgba(0, 0, 0, 0.8); }
  .theme_default__mSJ9-[class*='NewTaskContainer'] > [class*='NewTaskBlock'] {
    background-color: #FFFFFF;
    color: #1D1D1D; }
    .theme_default__mSJ9-[class*='NewTaskContainer'] > [class*='NewTaskBlock'] > [class*='NTClose'] {
      color: #1D1D1D; }

.theme_default__mSJ9-[class*='NewTaskMediaAttachmentList'] {
  border-color: transparent; }

.theme_default__mSJ9-[class*='MediaAttachmentList'] {
  background-color: rgba(228, 31, 40, 0.7); }

.theme_default__mSJ9-[class*='MediaAttachmentListItem'] {
  background-color: #1D1D1D;
  border-color: #FFFFFF; }

.theme_default__mSJ9-[class*='AlertMessageContainer'] {
  border-color: #FFCC01; }

.theme_default__mSJ9-[class*='AlertMessagePage'] {
  color: #FFFFFF; }

.theme_default__mSJ9-[class*='AlertLoader'] {
  border-color: #FFFFFF;
  border-top-color: #FFCC01; }

.theme_default__mSJ9-[class*='icon'] {
  color: inherit; }

.AlertMessagePage {
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000; }

.AlertMessageContainer {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-color: white;
  border: 1px solid #f3f3f3;
  /* Light grey */
  display: table;
  border-radius: 10px;
  margin: 0px auto;
  width: 300px;
  overflow: hidden; }

.AlertMessageTitle {
  text-align: center;
  font-weight: bold;
  margin: 20px;
  font-size: 18px;
  line-height: 22px; }

.AlertMessageText {
  white-space: pre;
  margin: 20px;
  font-size: 15px;
  line-height: 19px;
  text-align: center; }

.AlertMessageButtonContainer {
  text-align: center;
  overflow: hidden; }

.AlertMessageButtonCancel {
  padding: 0;
  height: 50px;
  border: none;
  border: 1px solid white;
  border-bottom-left-radius: 8px;
  background: none;
  width: 50%;
  color: white;
  font-weight: bold; }

.AlertMessageButtonAccept {
  padding: 0;
  height: 50px;
  border: none;
  border: 1px solid white;
  border-bottom-right-radius: 8px;
  background: none;
  width: 50%;
  color: white;
  font-weight: bold; }

.AlertMessageButtonAcceptSingle {
  padding: 0;
  height: 50px;
  border: none;
  border: 1px solid white;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: none;
  width: 100%;
  color: white;
  font-weight: bold; }

.button_button__2fC2n {
  height: 50px;
  padding: 0 15px;
  border-radius: 6px;
  border: none;
  font-size: 17px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  cursor: pointer;
  -webkit-transition: background-color .3s ease, opacity .3s ease, color .3s ease;
  transition: background-color .3s ease, opacity .3s ease, color .3s ease; }
  @media (min-width: 992px) {
    .button_button__2fC2n:hover {
      opacity: .7; } }

.button_textonly__2KsTG {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  border-radius: 0;
  padding: 0;
  background-color: transparent !important; }

.button_empty__3XT-Y {
  background-color: transparent;
  border: 1px solid black;
  border-radius: 6px; }

.button_border__FkXpC {
  border: 1px solid #000; }

.ContentOverlayPage {
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 12; }

.ContentOverlayContainer {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-color: white;
  border: 1px solid #f3f3f3;
  /* Light grey */
  display: table;
  border-radius: 10px;
  margin: 0px auto;
  overflow: hidden;
  padding: 20px; }

.ContentOverlayTitle {
  text-align: center;
  font-weight: bold;
  margin: 20px;
  font-size: 18px;
  line-height: 22px; }

.ContentOverlayText {
  white-space: pre;
  margin: 20px;
  font-size: 15px;
  line-height: 19px;
  text-align: center; }

.ContentOverlayButtonContainer {
  text-align: center;
  overflow: hidden; }

.buttonContainer {
  display: inline-block;
  width: 50%;
  padding: 0px 5px; }
  .buttonContainer .ContentOverlayButton {
    padding: 0;
    height: 50px;
    border: none;
    border: 1px solid white;
    border-radius: 8px;
    background: none;
    color: white;
    font-weight: bold;
    width: 100%;
    cursor: pointer; }
    .buttonContainer .ContentOverlayButton:hover {
      opacity: 0.5; }

.ContentOverlayButtonAccept {
  padding: 0;
  height: 50px;
  border: none;
  border: 1px solid white;
  border-bottom-right-radius: 8px;
  background: none;
  width: 50%;
  color: white;
  font-weight: bold; }

.ContentOverlayButtonAcceptSingle {
  padding: 0;
  height: 50px;
  border: none;
  border: 1px solid white;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: none;
  width: 100%;
  color: white;
  font-weight: bold; }

.mt-1-rem {
  margin-top: 1rem; }

.mt-2-rem {
  margin-top: 2rem; }

.mb-1-rem {
  margin-bottom: 1rem; }

.mb-2-rem {
  margin-bottom: 2rem; }

.pay-now {
  height: 2em;
  width: 100%; }

.pay-now-cancel {
  background-color: #424242 !important;
  height: 2em;
  width: 100%;
  color: #FFFFFF !important; }

.bold-text {
  font-weight: bold; }

.pay-now-hide {
  display: none; }

.mr-1-rem {
  margin-right: 1rem; }

.ml-1-rem {
  margin-left: 1rem; }

.forgotPassword_FPContainer__1ttZP {
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.forgotPassword_FPClose__1K6li {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 13px;
  top: 13px; }

.forgotPassword_FPBox__1NsDf {
  width: 100%;
  min-width: 300px;
  max-width: 360px;
  padding: 40px 30px;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center; }

@-webkit-keyframes forgotPassword_appear-from-top__3wuSU {
  from {
    -webkit-transform: translateY(-1500px);
            transform: translateY(-1500px); }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes forgotPassword_appear-from-top__3wuSU {
  from {
    -webkit-transform: translateY(-1500px);
            transform: translateY(-1500px); }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

.forgotPassword_appear__PWbMP {
  -webkit-animation: forgotPassword_appear-from-top__3wuSU .3s ease;
          animation: forgotPassword_appear-from-top__3wuSU .3s ease; }

.forgotPassword_disappear__2ffMn {
  animation: forgotPassword_appear-from-top__3wuSU .3s ease reverse; }

.forgotPassword_FPSelect__2tkxr {
  flex: 1 1;
  font-size: 12px !important;
  overflow: hidden;
  background-color: #ffcc01 !important;
  border: 1px solid #fff;
  font-family: Arial;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: #fff !important;
  font-weight: 700; }

.inputText_inputText__29AJA {
  /* display:flex; */
  /* margin:10px; */
  padding: 0px 10px;
  height: 50px;
  /* width:240px; */
  font-size: 16px;
  border-radius: 8px;
  border: none; }

.inputText_inputMessage__10QKY {
  height: 40px;
  flex: 1 1;
  border-radius: 5px; }

.inputField_inputField__1jcWa {
  flex: 1 1;
  font-size: 16px;
  overflow: hidden;
  background-color: #ffcc01 !important;
  border: 1px solid #fff;
  font-family: Arial;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: #000 !important;
  font-weight: 700; }

.inputField_inputField__1jcWa::-webkit-input-placeholder {
  font-size: 12px;
  color: #fff !important; }

.inputField_inputField__1jcWa::-moz-placeholder {
  font-size: 12px;
  color: #fff !important; }

.inputField_inputField__1jcWa::-ms-input-placeholder {
  font-size: 12px;
  color: #fff !important; }

.inputField_inputField__1jcWa::placeholder {
  font-size: 12px;
  color: #fff !important; }

.inputField_inputMessage__VK7Wd {
  height: 40px;
  flex: 1 1;
  border-radius: 5px; }

.signIn_SignInForm__3GQZu {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }

.signIn_ForgotPasswordButton__7VF8j {
  flex: 1 1;
  margin-top: 0px;
  text-align: right;
  background: none;
  border: none;
  color: white; }

.signIn_ForgotPasswordButton__7VF8j:active {
  border: none;
  outline: none; }

.signIn_ForgotPasswordButton__7VF8j:focus {
  border: none;
  outline: none; }

.signIn_SignInLogo__Mkdtf {
  align-self: center; }

.signIn_SignInSpacerSmall__1apl4 {
  height: 4px; }

.signIn_SignInSpacer__1XnF7 {
  height: 20px; }

.picture_picture__1A6-Y {
  width: auto;
  height: auto;
  max-width: 100%;
  object-fit: cover; }

.picture_logo__2F-th {
  width: 40px;
  height: 40px;
  border-radius: 50%; }

.picture_user__andkn {
  width: 40px;
  height: 40px;
  border-radius: 50%; }
  @media (min-width: 768px) {
    .picture_user__andkn {
      width: 50px;
      height: 50px; } }

.tokenSignIn_SignInForm__23R1r {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background: rgba(38, 39, 38, 0.5);
  border-radius: 10px;
  align-items: center; }

.tokenSignIn_ForgotPasswordButton__1ahmw {
  flex: 1 1;
  margin-top: 0px;
  text-align: right;
  background: none;
  border: none;
  color: white; }

.tokenSignIn_ForgotPasswordButton__1ahmw:active {
  border: none;
  outline: none; }

.tokenSignIn_ForgotPasswordButton__1ahmw:focus {
  border: none;
  outline: none; }

.tokenSignIn_SignInLogo__2gaBY {
  align-self: center;
  width: 100px;
  margin: 10px 10px; }

.tokenSignIn_SignInSpacerSmall__3qBD4 {
  height: 10px; }

.tokenSignIn_SignInSpacer__3kszC {
  height: 20px; }

.tokenSignIn_UserName__2ZrRm {
  width: 250px; }

.tokenSignIn_Password__Ax-xI {
  width: 250px; }

.tokenSignIn_SButton__3BbPh {
  width: 210px; }

.titleBar_titleBar__11aCD {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background: #2e2e2e;
  color: white;
  position: relative;
  z-index: 6; }

.titleBar_phoneIcon__1-a04 {
  color: #000 !important; }

.titleBar_TitleLogo__2q1nP {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px; }

.titleBar_TitleText__1XzZq {
  color: #009fe3;
  font-weight: bold; }

.titleBar_TitleUserIcon__2zmVF {
  height: 40px;
  width: 40px;
  object-fit: cover;
  margin-left: 10px;
  border-radius: 50%; }

.titleBar_MyTitle__2zAyK button {
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border: none;
  background: #009fe3;
  color: #ffffff;
  outline: none;
  font-size: 14px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 10px 14px; }

.titleBar_MyTitle__2zAyK button:active {
  outline: none;
  color: black; }

.titleBar_MyTitle__2zAyK button:focus {
  outline: 0; }

.titleBar_MyTitleSpacer__2KJA2 {
  flex: 1 1; }

.titleBar_SocketConnected__1j9dz {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: lightgreen;
  margin-right: 10px; }

.titleBar_SocketDisconnected__3Jk7i {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: red;
  margin-right: 10px; }

.taskItem_taskCard__1-ok5 {
  width: 100%;
  min-height: 90px;
  position: relative;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
  border: none;
  margin-bottom: 20px;
  border-radius: 5px;
  cursor: pointer; }
  @media (min-width: 992px) {
    .taskItem_taskCard__1-ok5 {
      -webkit-transition: opacity .3s ease;
      transition: opacity .3s ease; }
      .taskItem_taskCard__1-ok5:hover {
        opacity: .7; } }
  .taskItem_taskCard__left__2rZML {
    padding-right: 10px;
    min-width: 60px; }
  .taskItem_taskCard__right__FPLxt {
    padding: 5px 0;
    border-left: 1px solid #000;
    padding-left: 10px;
    padding-right: 15px;
    text-align: left; }
  .taskItem_taskCard__arrow__32yot {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    right: 13px;
    top: 50%;
    border-top: 2px solid black;
    border-right: 2px solid black;
    -webkit-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg); }

.taskListContainer_TaskListContainer__q0orQ {
  padding: 5px;
  margin: 5px;
  border: 1px dashed white;
  flex: 1 1;
  display: flex;
  flex-direction: column; }

.taskListContainer_TaskList__1zzB5 {
  flex: 1 1;
  padding: 30px;
  overflow: hidden; }

.taskListContainer_NoTaskContainer__3odMu {
  color: white;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: center;
  justify-content: center; }

.taskListContainer_NoTaskContainerText__2rX7L {
  margin-bottom: 20px;
  margin-top: 10px; }

.taskCreationOptions_TaskCreationOptionsContainer__26-mE {
  height: 80px;
  padding-top: 1px;
  text-align: center; }

.taskCreationOptions_TaskOptionSpacer__1CiHp {
  width: 2px; }

.taskCreationOptions_TaskCreationOption__2N3G1 {
  border: 2px solid green;
  text-align: center;
  height: 30px;
  width: 150px;
  font-weight: bold;
  background: #1991EB;
  color: #fff;
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 5px;
  border: none; }

.taskCreationOptions_TaskCreationOption__2N3G1:active {
  outline: none;
  background: #009fe3;
  color: #ffffff; }

.taskCreationOptions_TaskCreationOption__2N3G1:focus {
  outline: 0; }

.taskCreationOptions_wrapper-creation-options__2f_Jb {
  position: fixed;
  bottom: 0px; }

.taskCreationOptions_stupid-fn-button__3KKUB {
  position: fixed;
  bottom: 10px;
  z-index: 999; }

.taskTypeSwitch_TaskToggleContainer__fmTlk {
  display: flex;
  padding: 1px 0px; }

.taskTypeSwitch_TaskToggleContainer__fmTlk button {
  margin-right: 10px; }

.taskTypeSwitch_TaskToggleContainer__fmTlk button:active {
  border: none; }

.taskTypeSwitch_TaskToggleContainer__fmTlk button:focus {
  outline: 0; }

.taskTypeSwitch_TaskToggleButtonActive__2BFTr {
  width: 50%;
  height: 50px;
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  margin: 0;
  left: 1px;
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Muli", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14.0px;
  color: white;
  text-align: center;
  line-height: 18.0px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: #FACA46;
  border-bottom-width: 3px; }

.taskTypeSwitch_TaskToggleButtonInActive__1v8ru {
  width: 50%;
  height: 50px;
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  margin: 0;
  left: 1px;
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Muli", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14.0px;
  color: white;
  text-align: center;
  line-height: 18.0px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: #626870;
  border-bottom-width: 3px; }

.taskTypeSwitch_TaskListSection__1Z6JI {
  z-index: -200; }

.MessageItemContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column; }

.MessageCell {
  display: flex;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .MessageCell {
      margin-bottom: 30px; } }

.MessageItemSpacer {
  height: 6px; }

.ChatIconContainerUser {
  display: flex;
  flex-direction: column;
  margin-left: 10px; }

.messageIconWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }

.MessageChatIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%; }

.MessageContentApp {
  background: #cdcdcd;
  color: black;
  border-radius: 10px;
  padding: 10px;
  width: 10px;
  height: auto;
  flex: 1 1;
  text-align: left;
  flex-direction: column;
  display: flex; }

.messageContent {
  background: #009fe3;
  color: white;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  height: auto;
  flex: 1 1;
  flex-direction: column;
  display: flex;
  position: relative; }
  .messageContent::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    right: 0;
    bottom: 15px;
    -webkit-transform: translateX(50%) rotate(45deg);
            transform: translateX(50%) rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: #009fe3; }
    @media (min-width: 768px) {
      .messageContent::after {
        bottom: 22px; } }

.messageUser {
  text-align: right; }

.messageApp::after {
  right: auto;
  left: 0;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg); }

.MediaThumbnail {
  background: #2e2e2e;
  padding: 8px;
  border-radius: 10px;
  object-fit: cover;
  margin: 0px auto;
  max-width: 80%;
  max-height: 400px; }

.MediaPlaceHolder {
  display: none; }

.AudioPlayer {
  align-self: flex-end; }

.PaymentContainer {
  border-radius: 10px;
  display: flex;
  flex-direction: column; }

.PaymentTitle {
  background: black;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  font-weight: bold; }

.PaymentDetailsContainer {
  display: flex;
  flex: 1 1; }

.PaymentDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: #009fe3;
  flex: 1 1;
  padding: 10px;
  border-bottom-left-radius: 10px; }

.PaymentStatusContainer {
  border: 1px solid white;
  padding: 10px;
  border-bottom-right-radius: 10px; }

.PaymentAmount {
  font-weight: bold;
  padding: 10px;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.PaymentStatusPending {
  background: #bebe00;
  text-align: center;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white; }

.PaymentStatus {
  text-align: center;
  background: green;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white; }

.VendorContainer {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  border-radius: 10px;
  justify-content: center; }

.VendorCell {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  height: 500px;
  max-width: 300px; }

.VendorTitle {
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: black;
  padding: 20px;
  font-weight: bold;
  justify-content: space-between; }

.VendorDetails {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1 1;
  background: white;
  color: #2e2e2e;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }

.VendorDetailsItem {
  margin-bottom: 10px; }

.TimeStamp {
  font-size: 10px;
  margin: 4px 0px;
  margin-bottom: 8px; }

@media (min-width: 992px) {
  .MessagePadding {
    width: 25%; } }

.TaskDetailsContainer {
  flex: 1 1;
  padding: 30px 15px;
  overflow-y: auto;
  position: relative;
  background-image: url("https://stratitude.co.za/Sites/www.mtnclub.co.za/img/landing/test.svg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (min-width: 576px) {
    .TaskDetailsContainer {
      padding: 30px; } }

.taskDetailsHead {
  padding: 15px;
  display: flex;
  align-items: center;
  z-index: 1; }
  @media (min-width: 576px) {
    .taskDetailsHead {
      padding: 30px; } }

.taskTitle {
  padding-bottom: 8px;
  padding-right: 20px;
  border-bottom: 1px solid black;
  width: auto;
  display: inline-block;
  height: auto;
  font-size: 22px;
  line-height: 26px;
  font-weight: normal;
  z-index: 2;
  max-width: 50%; }
  @media (min-width: 992px) {
    .taskTitle {
      max-width: 70%;
      margin-right: 25px; } }

.TaskEmptyImage {
  opacity: 0.5;
  margin-top: auto;
  margin-bottom: 20px;
  margin-right: 20px;
  object-fit: contain;
  align-self: flex-end;
  font-weight: bold;
  color: white;
  text-align: right;
  position: absolute;
  right: 20px;
  bottom: 20px; }

.MessageInputContainer {
  background: #F5EC0F;
  /* height:50px; */
  display: flex;
  align-items: center;
  padding: 10px 15px;
  z-index: 2; }

.MessageInputContainer input {
  flex: 1 1; }

.MessageInputContainer img {
  width: 30px;
  height: 30px; }

.FileSystem {
  display: none; }

.MessageInputContainer button {
  display: flex;
  width: 40px;
  height: 40px;
  align-content: center;
  justify-content: center;
  margin: 4px;
  background: none;
  border: none;
  cursor: pointer; }

.MessageInputContainer img {
  width: 40px;
  height: 40px;
  object-fit: contain; }

.MessageInputContainer button:active {
  border: none;
  background: #009fe3;
  border-radius: 10px; }

.MessageInputContainer button:focus {
  outline: 0; }

.MediaAttachmentList {
  /* margin-top: 4px; */
  padding: 10px;
  overflow: auto;
  align-items: center;
  background: #009fe3;
  max-height: 250px; }
  @media (min-width: 768px) {
    .MediaAttachmentList {
      max-height: 450px; } }

.MediaAttachmentContainer {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: center; }

.MediaAttachmentListItem {
  display: flex;
  background: #2e2e2e;
  margin: 6px;
  border: 2px solid white;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  overflow: hidden; }

.MediaAttachmentListItem img {
  height: 70px;
  width: 70px;
  border-radius: 4px;
  object-fit: contain; }

.userProfileContainer {
  min-width: 350px;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  height: 100%;
  z-index: 11;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  overflow-x: hidden;
  padding: 15px; }
  @media (min-width: 1200px) {
    .userProfileContainer {
      position: relative;
      width: 20%;
      max-width: 20%; } }

.userProfileHide {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  @media (min-width: 1200px) {
    .userProfileHide {
      -webkit-transform: none;
              transform: none; } }

.userAvatarContainer {
  display: flex;
  justify-content: flex-start;
  background-color: #000 !important;
  border-radius: 5px;
  min-height: 80px;
  padding: 10px; }

.UserLabel {
  font-weight: bold; }

.UserLabelSpacer {
  height: 10px; }

.ProfileSpacer {
  flex: 1 1; }

.userLogout {
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer; }

.userCloseButton {
  color: #fff;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: 1px solid #fff;
  cursor: pointer; }

.userImageWrapp {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  position: relative; }

.userImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0; }

.userImageSelect {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer; }

.mt-70 {
  margin-top: 70px; }

.MMenu_P {
  font-size: 20px;
  margin-top: 5px;
  padding-top: 5px; }

.Hey_Title {
  font-size: 40px;
  line-height: 60px;
  color: #fdc009; }

.Hey_Copy {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 200;
  line-height: 36px; }

.MMenu_Hey {
  margin-top: 20px; }

.MMenu_P {
  font-size: 20px; }

.MModal_Button_Hey {
  color: #000 !important;
  font-weight: bold;
  font-size: 11px;
  background-color: #FFCC01;
  background-size: 100%;
  background-repeat: no-repeat;
  font-weight: 400;
  padding: 15px 15px;
  border: 2px solid #000;
  margin: 20px;
  line-height: 60px;
  cursor: help; }

.neonGo {
  -webkit-animation: flicker 3.2s infinite alternate;
          animation: flicker 3.2s infinite alternate; }

.neonPop {
  -webkit-animation: flicker2 4s infinite alternate;
          animation: flicker2 4s infinite alternate; }

.neonChill {
  -webkit-animation: flicker3 3.5s infinite alternate;
          animation: flicker3 3.5s infinite alternate; }

:root {
  /* Base font size */
  /* Set neon color */
  --neon-text-color: #f40;
  --neon-border-color: #ea00ff;
  --neon-border-color2: #ffeb49;
  --neon-border-color3: #00c59e; }

/* Animate neon flicker */
@-webkit-keyframes flicker {
  0%, 35%, 55%, 80%, 95% {
    -webkit-filter: drop-shadow(0px 0px 15px #ea00ff);
    -webkit-filter: drop-shadow(0px 0px 15px var(--neon-border-color)); }
  10%, 45%, 65%, 90%, 100% {
    -webkit-filter: drop-shadow(0px 0px 9px #ea00ff);
    -webkit-filter: drop-shadow(0px 0px 9px var(--neon-border-color)); }
  20%, 24%, 55% {
    text-shadow: none;
    box-shadow: none; } }
@keyframes flicker {
  0%, 35%, 55%, 80%, 95% {
    -webkit-filter: drop-shadow(0px 0px 15px #ea00ff);
    -webkit-filter: drop-shadow(0px 0px 15px var(--neon-border-color)); }
  10%, 45%, 65%, 90%, 100% {
    -webkit-filter: drop-shadow(0px 0px 9px #ea00ff);
    -webkit-filter: drop-shadow(0px 0px 9px var(--neon-border-color)); }
  20%, 24%, 55% {
    text-shadow: none;
    box-shadow: none; } }

@-webkit-keyframes flicker2 {
  10%, 45%, 65%, 90%, 100% {
    -webkit-filter: drop-shadow(0px 0px 15px #ffeb49);
    -webkit-filter: drop-shadow(0px 0px 15px var(--neon-border-color2));
    filter: drop-shadow(0px 0px 15px #ffeb49);
    filter: drop-shadow(0px 0px 15px var(--neon-border-color2)); }
  0%, 15%, 55%, 80%, 95% {
    -webkit-filter: drop-shadow(0px 0px 9px #ffeb49);
    -webkit-filter: drop-shadow(0px 0px 9px var(--neon-border-color2));
    filter: drop-shadow(0px 0px 9px #ffeb49);
    filter: drop-shadow(0px 0px 9px var(--neon-border-color2)); }
  20%, 24%, 55% {
    text-shadow: none;
    box-shadow: none; } }

@keyframes flicker2 {
  10%, 45%, 65%, 90%, 100% {
    -webkit-filter: drop-shadow(0px 0px 15px #ffeb49);
    -webkit-filter: drop-shadow(0px 0px 15px var(--neon-border-color2));
    filter: drop-shadow(0px 0px 15px #ffeb49);
    filter: drop-shadow(0px 0px 15px var(--neon-border-color2)); }
  0%, 15%, 55%, 80%, 95% {
    -webkit-filter: drop-shadow(0px 0px 9px #ffeb49);
    -webkit-filter: drop-shadow(0px 0px 9px var(--neon-border-color2));
    filter: drop-shadow(0px 0px 9px #ffeb49);
    filter: drop-shadow(0px 0px 9px var(--neon-border-color2)); }
  20%, 24%, 55% {
    text-shadow: none;
    box-shadow: none; } }

@-webkit-keyframes flicker3 {
  0%, 15%, 55%, 80%, 95% {
    -webkit-filter: drop-shadow(0px 0px 15px #00c59e);
    -webkit-filter: drop-shadow(0px 0px 15px var(--neon-border-color3));
    filter: drop-shadow(0px 0px 15px #00c59e);
    filter: drop-shadow(0px 0px 15px var(--neon-border-color3)); }
  10%, 45%, 65%, 90%, 100% {
    -webkit-filter: drop-shadow(0px 0px 9px #00c59e);
    -webkit-filter: drop-shadow(0px 0px 9px var(--neon-border-color3));
    filter: drop-shadow(0px 0px 9px #00c59e);
    filter: drop-shadow(0px 0px 9px var(--neon-border-color3)); }
  20%, 24%, 55% {
    text-shadow: none;
    box-shadow: none; } }

@keyframes flicker3 {
  0%, 15%, 55%, 80%, 95% {
    -webkit-filter: drop-shadow(0px 0px 15px #00c59e);
    -webkit-filter: drop-shadow(0px 0px 15px var(--neon-border-color3));
    filter: drop-shadow(0px 0px 15px #00c59e);
    filter: drop-shadow(0px 0px 15px var(--neon-border-color3)); }
  10%, 45%, 65%, 90%, 100% {
    -webkit-filter: drop-shadow(0px 0px 9px #00c59e);
    -webkit-filter: drop-shadow(0px 0px 9px var(--neon-border-color3));
    filter: drop-shadow(0px 0px 9px #00c59e);
    filter: drop-shadow(0px 0px 9px var(--neon-border-color3)); }
  20%, 24%, 55% {
    text-shadow: none;
    box-shadow: none; } }

.modalBackground {
  position: absolute;
  top: 0;
  left: 0;
  background: #e41f28;
  padding: 20px;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-image: url(../../static/media/turntable.89d7f74c.png);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  opacity: 1;
  overflow: none; }
  .modalBackground:focus {
    outline: 0; }

.modalContainer {
  overflow-y: auto;
  padding: 40px 20px 20px;
  background: #e41f28; }
  .modalContainer .closeModal {
    position: fixed;
    top: 40px;
    right: 50px;
    background: none;
    border: 1px solid #fff;
    color: #fdc009;
    cursor: pointer; }
  .modalContainer .modalContent {
    overflow: scroll; }
    .modalContainer .modalContent h3 {
      text-align: center;
      color: #fdc009;
      font-size: 32px;
      margin-bottom: 10px; }
    .modalContainer .modalContent .IframeContainer {
      width: 100%;
      height: 620px;
      overflow: scroll; }
      .modalContainer .modalContent .IframeContainer iframe {
        overflow: scroll;
        width: 100% !important;
        height: 100% !important;
        margin: auto;
        display: block; }

.tasksView_MyPage__1S8Sl {
  width: 100vw;
  height: 100vh;
  flex-direction: column; }

.tasksView_MyInnerPage__1exWI {
  width: 100vw;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column; }

.tasksView_MyColumnContainer__39SKo {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  overflow: hidden;
  position: relative; }

.tasksView_taskListColumn__P3TVq {
  width: 100%;
  display: flex;
  flex-direction: column; }
  @media (min-width: 992px) {
    .tasksView_taskListColumn__P3TVq {
      border-right: 2px solid black;
      width: 25%;
      min-width: 350px; } }

.tasksView_chatArea__1KE2R {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 5;
  -webkit-transform: translateX(-110vw);
          transform: translateX(-110vw);
  -webkit-transition: -webkit-transform .3s ease;
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }
  @media (min-width: 992px) {
    .tasksView_chatArea__1KE2R {
      width: auto;
      position: relative;
      -webkit-transform: none;
              transform: none; } }

.tasksView_show__3cU_B {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @media (min-width: 992px) {
    .tasksView_show__3cU_B {
      -webkit-transform: none;
              transform: none; } }

/* .MyInput{
  margin-top:5px;
  padding:5px;
  border: 1px dashed black;
} */

.NewTaskContainer {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  /* justify-content: center; */ }

.NTClose {
  position: absolute;
  right: 13px;
  top: 13px;
  color: #fff; }

.FileSystem {
  display: none; }

.NewTaskBlock {
  background-color: #e41f28 !important;
  width: 100%;
  min-width: 300px;
  max-width: 360px;
  padding: 30px 10px;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  color: white; }
  @media (min-width: 576px) {
    .NewTaskBlock {
      padding: 40px 30px; } }

.NewTaskTitleContainer {
  display: flex;
  align-items: center; }

.NewTaskTitle {
  font-weight: bold;
  text-align: center;
  flex: 1 1;
  margin-bottom: 20px;
  text-transform: uppercase; }

.NewTaskInputContainer {
  display: flex;
  align-items: flex-end; }

.NewTaskContainer img {
  object-fit: contain; }

.NewTaskContainer button:active {
  border: none;
  background: #009fe3;
  border-radius: 10px; }

.NewTaskContainer button:focus {
  outline: 0; }

.InputWrapper {
  display: flex;
  flex: 1 1;
  margin: 0px 10px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #81BF3F;
  padding: 2px; }

.NewTaskInput {
  flex: 1 1;
  font-size: 16px;
  resize: none;
  outline: none;
  overflow: hidden;
  background-color: #fff;
  border: 2px solid #000;
  font-family: 'Arial';
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: #000;
  font-weight: bold; }

.NewTaskInputTA {
  min-height: 150px;
  flex: 1 1;
  font-size: 16px;
  resize: none;
  outline: none;
  overflow: hidden;
  background-color: #fff;
  border: 2px solid #000;
  font-family: 'Arial';
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: #000;
  font-weight: bold; }

.NewTaskInput::-webkit-input-placeholder {
  color: #efefef;
  font-weight: bold;
  font-size: 12px; }

.NewTaskInput::-moz-placeholder {
  color: #efefef;
  font-weight: bold;
  font-size: 12px; }

.NewTaskInput::-ms-input-placeholder {
  color: #efefef;
  font-weight: bold;
  font-size: 12px; }

.NewTaskInput::placeholder {
  color: #efefef;
  font-weight: bold;
  font-size: 12px; }

.NewTaskInputTA::-webkit-input-placeholder {
  color: #efefef;
  font-weight: bold;
  font-size: 12px; }

.NewTaskInputTA::-moz-placeholder {
  color: #efefef;
  font-weight: bold;
  font-size: 12px; }

.NewTaskInputTA::-ms-input-placeholder {
  color: #efefef;
  font-weight: bold;
  font-size: 12px; }

.NewTaskInputTA::placeholder {
  color: #efefef;
  font-weight: bold;
  font-size: 12px; }

.NewTaskMediaAttachmentList {
  display: flex;
  padding: 10px;
  overflow-y: auto;
  align-items: center;
  border-radius: 6px;
  margin-top: 6px;
  max-height: 250px; }
  .NewTaskMediaAttachmentList .NewTaskMediaAttachmentContainer {
    max-height: inherit;
    display: flex;
    flex-wrap: wrap;
    padding-right: 12px; }
    .NewTaskMediaAttachmentList .NewTaskMediaAttachmentContainer .NewTaskMediaAttachmentListItem {
      max-height: 204px;
      overflow: hidden;
      background: #2e2e2e;
      align-self: flex-start;
      padding: 2px;
      background-color: inherit; }
      .NewTaskMediaAttachmentList .NewTaskMediaAttachmentContainer .NewTaskMediaAttachmentListItem:not(:only-child) {
        flex: 0 1 50%; }
      .NewTaskMediaAttachmentList .NewTaskMediaAttachmentContainer .NewTaskMediaAttachmentListItem img {
        max-width: 100%;
        max-height: 200px;
        border: 2px solid white;
        border-radius: 6px; }
  .NewTaskMediaAttachmentList button {
    margin-left: auto; }

.NewTaskHeader {
  color: #fff; }

.NewTaskDescription {
  color: #fff;
  font-size: 12px; }

.NewTaskButtonsContainer {
  display: flex;
  align-items: center;
  margin-top: 10px; }
  .NewTaskButtonsContainer .NewTaskFileButton, .NewTaskButtonsContainer .NewTaskAudioButton {
    font-size: 24px;
    margin-right: 5px;
    width: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #FBB829;
    border: none;
    border-radius: 30px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    cursor: pointer; }
  .NewTaskButtonsContainer .NewTaskSubmit {
    height: 48px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #000;
    border: none;
    border-radius: 30px;
    font-weight: bold;
    color: #fff;
    width: 150px !important;
    margin-left: auto;
    cursor: pointer; }

/* .MyPage{
  width:100vw;
  height:100vh;
  border:1px solid black;
  display:flex;
  flex-direction: column;
} */
/* .MyColumnContainer{
  border: 1px solid cyan;
  display:flex;

  flex-direction: row;
  flex:1;
  overflow:hidden;
} */
/* .MyColumns {
  padding:5px;
  margin:5px;
  border:1px dashed white;
  flex:1;
  display:flex;
  flex-direction: column;
} */
/* .MyToggle{
  margin-bottom:5px;
  padding:5px;
  border: 1px dashed black;
} */
/* .MyList{
  flex:1;
  padding:5px;
  border: 1px solid black;
  overflow: scroll;
} */
/* .MyOptions{
  margin-top:5px;
  padding:5px;
  border: 1px dashed black;
} */
/* .MyChat {
  flex:1;
  padding:5px;
  border: 1px solid black;
  overflow: scroll;
} */
/* .MyInput{
  margin-top:5px;
  padding:5px;
  border: 1px dashed black;
} */

.signup_FPContainer__1ox0n {
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.signup_FPClose__53NjM {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 13px;
  top: 13px; }

.signup_FPBox__3_V0p {
  width: 100%;
  min-width: 300px;
  max-width: 360px;
  padding: 40px 30px;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center; }

@-webkit-keyframes signup_appear-from-top__dp5aR {
  from {
    -webkit-transform: translateY(-1500px);
            transform: translateY(-1500px); }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes signup_appear-from-top__dp5aR {
  from {
    -webkit-transform: translateY(-1500px);
            transform: translateY(-1500px); }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

.signup_appear__28jxR {
  -webkit-animation: signup_appear-from-top__dp5aR .3s ease;
          animation: signup_appear-from-top__dp5aR .3s ease; }

.signup_disappear__dTU3j {
  animation: signup_appear-from-top__dp5aR .3s ease reverse; }

.signup_FPSelect__2azRq {
  flex: 1 1;
  font-size: 12px !important;
  overflow: hidden;
  background-color: #ffcc01 !important;
  border: 1px solid #fff;
  font-family: Arial;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: #fff !important;
  font-weight: 700; }

