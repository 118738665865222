.userProfileContainer{
  min-width: 350px;
  color:white;
  display:flex;
  flex-direction: column;
  padding:20px;
  position: fixed;
  left:0;
  top:0;
  width: 300px;
  height: 100%;
  z-index: 11;
  transition: all .3s ease;
  overflow-x: hidden;
  padding: 15px;
  @media (min-width:1200px) {
    position: relative;
    width: 20%;
    max-width: 20%;
    // border-right: 2px solid black;
  }
}

.userProfileHide{
  transform: translateX(-100%);
  @media (min-width:1200px) {
    transform: none;
  }
}

.userAvatarContainer {
  display: flex;
  justify-content: flex-start;
  background-color: #000 !important;
  border-radius: 5px;
  min-height: 80px;
  padding: 10px;
}


.UserLabel{
  font-weight: bold;
}

.UserLabelSpacer{
  height:10px;
}

.ProfileSpacer{
  flex:1
}

.userLogout{
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
}

.userCloseButton{
  color: #fff;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: 1px solid #fff;
  cursor: pointer;
}

.userImageWrapp{
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  position: relative;
}

.userImage{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.userImageSelect{
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.mt-70 {
  margin-top:70px;
}
